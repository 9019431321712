import { createTheme, ThemeProvider } from '@material-ui/core'
import React from 'react'

import { Authenticated } from '@eqworks/common-login'

import Commutron from './commutron'


const muiTheme = createTheme({
  palette: {
    type: 'dark',
  },
})

const App = () => {

  return (
    <Authenticated product='locus'>
      <ThemeProvider theme={muiTheme}>
        <Commutron />
      </ThemeProvider>
    </Authenticated>
  )
}

export default App
