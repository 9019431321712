import React from 'react'
import PropTypes from 'prop-types'

import { Card } from '@material-ui/core'


const CustomCard = ({ active, setActive, style, ...props }) => (
  <Card
    onMouseEnter={() => setActive(true)}
    onMouseLeave={() => setActive(false)}
    style={{
      ...style,
      transition: 'background 0.5s',
      background: active ? '#4d4d4d' : '#4a4a4a52',
    }}
    {...props}
  />
)

CustomCard.propTypes = {
  active: PropTypes.bool.isRequired,
  setActive: PropTypes.func.isRequired,
  style: PropTypes.object,
}
CustomCard.defaultProps = {
  style: {},
}

export default CustomCard
