import { createStore, combineReducers, applyMiddleware } from 'redux'
import { taskMiddleware } from 'react-palm/tasks'


const initialState = {}
const reducers = combineReducers({ test: ()=>'test reducer' })

export default createStore(
  reducers,
  initialState,
  applyMiddleware(taskMiddleware)
)
