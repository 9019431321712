import React, { useState, useEffect } from 'react'
import { AmbientLight, PointLight, LightingEffect } from '@deck.gl/core'
import { PolygonLayer, GeoJsonLayer } from '@deck.gl/layers'
import { TripsLayer } from '@deck.gl/geo-layers'
import { DeckGL } from '@deck.gl/react'

import CustomCard from './components/custom-card'
import { Button, CardActions, FormControlLabel, FormGroup, Switch, Typography, IconButton, Slider } from '@material-ui/core'
import { Stop, PlayArrow, FastForward } from '@material-ui/icons'
import { AutoSizer } from 'react-virtualized'
import { StaticMap } from 'react-map-gl'
import mapboxgl from 'mapbox-gl'


// https://github.com/mapbox/mapbox-gl-js/issues/10565
// https://stackoverflow.com/a/69489231
// // eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default

//todo: clean up or split in components, initially separate components, state was not reflecting properly...
//import DeckGLMap from './deckgl-map'
//import MapFilter from './map-filter'

import {
  goDeckGLData, miWayDeckGLData, ttcDeckGLData, upExpressDeckGLData, yorkDeckGLData, ttcSubwayGeoJsonData,
  startDate, endDate,
} from './sample-data'


const ambientLight = new AmbientLight({
  color: [255, 255, 255],
  intensity: 1.0,
})

const pointLight = new PointLight({
  color: [255, 255, 255],
  intensity: 2.0,
  position: [-74.05, 40.7, 8000],
})

const lightingEffect = new LightingEffect({ ambientLight, pointLight })

// basic `rgb(255, 255, 255) to [255,255,255, 150]
// todo: use d3 color lib if more flexibility required
const colorToRGBArray = (rgb)=>{
  let sep = rgb.indexOf(',') > -1 ? ',' : ' '
  return [...rgb.substr(4).split(')')[0].split(sep).map(v=>Number(v)), 150]
}

const material = {
  ambient: 0.1,
  diffuse: 0.6,
  shininess: 32,
  specularColor: [60, 64, 70],
}

const DEFAULT_THEME = {
  buildingColor: [74, 80, 87],
  trailColor0: [253, 128, 93],
  trailColor1: [23, 184, 190],
  material,
  effects: [lightingEffect],
}

const defaultLayers = {
  tripTTC: {
    name: 'TTC',
    layerTemplate: {
      id: 'trips-ttc',
      data: ttcDeckGLData,
      getPath: d => d.path,
      getTimestamps: d => d.timestamps.map(p => p - startDate),
      getColor: [218, 37, 29],
      opacity: 0.5,
      widthMinPixels: 2,
      // rounded: true,
      trailLength: 200,
      currentTime: 0,
      fadeTrail: true,
      shadowEnabled: false,
    },
    layerType: TripsLayer,
    selected: true,
    color: 'rgb(218, 37, 29)',
  },
  tripYork: {
    name: 'York',
    layerTemplate: {
      id: 'trips-york',
      data: yorkDeckGLData,
      getPath: d => d.path,
      getTimestamps: d => d.timestamps.map(p => p - startDate),
      getColor: [0, 110, 199],
      opacity: 0.5,
      widthMinPixels: 2,
      // rounded: true,
      trailLength: 200,
      currentTime: 0,
      fadeTrail: true,
      shadowEnabled: false,
    },
    layerType: TripsLayer,
    selected: true,
    color: 'rgb(0, 110, 199)',
  },
  tripMiWay: {
    name: 'miWay',
    layerTemplate: {
      id: 'trips-miway',
      data: miWayDeckGLData,
      getPath: d => d.path,
      getTimestamps: d => d.timestamps.map(p => p - startDate),
      getColor: [242, 99, 34],
      opacity: 0.5,
      widthMinPixels: 2,
      // rounded: true,
      trailLength: 200,
      currentTime: 0,
      fadeTrail: true,
      shadowEnabled: false,
    },
    layerType: TripsLayer,
    selected: true,
    color: 'rgb(242, 99, 34)',
  },
  tripGO: {
    name: 'GO',
    layerTemplate: {
      id: 'trips-go',
      data: goDeckGLData,
      getPath: d => d.path,
      getTimestamps: d => d.timestamps.map(p => p - startDate),
      getColor: [74, 119, 41],
      opacity: 0.5,
      widthMinPixels: 2,
      // rounded: true,
      trailLength: 200,
      currentTime: 0,
      fadeTrail: true,
      shadowEnabled: false,
    },
    layerType: TripsLayer,
    selected: true,
    color: 'rgb(74, 119, 41)',
  },
  tripUP: {
    name: 'UP Express',
    layerTemplate: {
      id: 'trips-up',
      data: upExpressDeckGLData,
      getPath: d => d.path,
      getTimestamps: d => d.timestamps.map(p => p - startDate),
      getColor: [217, 153, 79],
      opacity: 0.5,
      widthMinPixels: 2,
      // rounded: true,
      trailLength: 200,
      currentTime: 0,
      fadeTrail: true,
      shadowEnabled: false,
    },
    layerType: TripsLayer,
    selected: true,
    color: 'rgb(217, 153, 79)',
  },
  tripCommute: {
    name: 'Commute',
    layerTemplate: {
      id: 'trips-commute',
      data: 'https://locus-cdn.s3.amazonaws.com/assets/toronto-trips-20211022.json',
      getPath: d => d.path,
      getTimestamps: d => d.timestamps.map(p => p - startDate),
      getColor: [64, 224, 208],
      opacity: 0.5,
      widthMinPixels: 2,
      // rounded: true,
      trailLength: 200,
      currentTime: 0,
      fadeTrail: true,
      shadowEnabled: false,
    },
    layerType: TripsLayer,
    selected: false,
    color: 'rgb(64, 224, 208)',
  },
  ttcSubway: {
    name: 'Toronto Subway',
    layerTemplate: {
      id: 'subway-layer',
      data: ttcSubwayGeoJsonData,
      pickable: true,
      stroked: false,
      filled: true,
      extruded: true,
      pointType: 'circle',
      lineWidthScale: 20,
      lineWidthMinPixels: 2,
      getFillColor: [255, 255, 255, 150],
      getLineColor: d => colorToRGBArray(d.properties.color),
      getPointRadius: 35,
      getLineWidth: 1,
      getElevation: 30,
    },
    layerType: GeoJsonLayer,
    selected: true,
    color: 'rgb(218, 37, 29)',
  },
  buildings:
  {
    name: 'Buildings 3D',
    layerTemplate: {
      id: 'buildings',
      data: 'https://locus-cdn.s3.amazonaws.com/assets/toronto-buildings.json',
      extruded: true,
      wireframe: false,
      opacity: 0.5,
      getPolygon: f => f.polygon,
      getElevation: f => f.height,
      getFillColor: DEFAULT_THEME.buildingColor,
      material: DEFAULT_THEME.material,
    },
    layerType: PolygonLayer,
    selected: false,
    color: 'gray',
  },
}

const mapboxApiAccessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN || process.env.MAPBOX_ACCESS_TOKEN || ''

const INITIAL_VIEW_STATE = {
  longitude: -79.39,
  latitude: 43.66,
  zoom: 13,
  pitch: 45,
  bearing: 0,
}

const compileLayers = (layers, time, selectLayer) => {
  return Object.fromEntries(
    Object.entries(layers).map(([key, val]) => {
      val.layer = new val.layerType({ ...val.layerTemplate, currentTime: time })

      if (key === selectLayer) {
        val.selected = !val.selected
      }

      return [key, val]
    })
  )
}

const useAnimationFrame = callback => {
  // Use useRef for mutable variables that we want to persist
  // without triggering a re-render on their change
  const requestRef = React.useRef()
  const previousTimeRef = React.useRef()

  const animate = time => {
    if (previousTimeRef.current != undefined) {
      const deltaTime = time - previousTimeRef.current
      callback(deltaTime)
    }
    previousTimeRef.current = time
    requestRef.current = requestAnimationFrame(animate)
  }

  React.useEffect(() => {
    requestRef.current = requestAnimationFrame(animate)
    return () => cancelAnimationFrame(requestRef.current)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) // Make sure the effect runs only once
}


const Commutron = () => {
  const theme = DEFAULT_THEME
  const initialViewState = INITIAL_VIEW_STATE
  const loopLength = endDate - startDate

  const [layers, setLayers] = useState({})

  const [running, setRunning] = useState(false)
  const [time, setTime] = useState(0)
  const availableSpeeds = [5, 10, 20, 50]
  const [animationSpeed, setAnimationSpeed] = useState(availableSpeeds[1])
  const animationSpeedRef = React.useRef(availableSpeeds[1])
  const runningRef = React.useRef()

  const [tentativeTime, setTentativeTime] = useState(time)
  const [changingTime, setChangingTime] = useState(false)
  const [showSpeedIcon, setShowSpeedIcon] = useState(false)
  const [interactingWithControls, setInteractingWithControls] = useState(false)

  useEffect(() => {
    animationSpeedRef.current = animationSpeed
  }, [animationSpeed])

  useEffect(
    () => {
      setLayers(compileLayers(defaultLayers, 0))
    }, []
  )

  useEffect(
    () => {
      setLayers(layers => compileLayers(layers, time))
    },
    [time],
  )

  useAnimationFrame(() => {
    if (runningRef.current) {
      setTime(t => (t + animationSpeedRef.current) % loopLength)
    }
  })

  const selectionChanged = (key) => {
    setLayers(layers => compileLayers(layers, time, key))
  }

  const handlePlayPause = () => {
    setRunning(r => !r)
    runningRef.current = !running
  }

  const handleSpeedIncrease = () => setAnimationSpeed(availableSpeeds[availableSpeeds.indexOf(animationSpeed) + 1] ?? availableSpeeds[0])

  const selectedLayers = Object.values(layers).filter(v => v.selected).map(s => s.layer)

  return (
    <>
      {/*<DeckGLMap layers={selectedLayers} advance={advance} time={time} running={running} />
       <MapFilter layers={layers} onChange={selectionChanged} time={time} playPause={playPause} running={running} /> */}
      <div style={{ width: '100vw', height: '100vh' }}>
        <AutoSizer>
          {({ height, width }) => (
            <DeckGL
              layers={selectedLayers}
              effects={theme.effects}
              initialViewState={initialViewState}
              controller={true}
              height={height}
              width={width}
              getTooltip={({ object }) => object && (object.properties.name || '')}
            >
              <StaticMap
                reuseMaps
                mapStyle="mapbox://styles/dilshaneq/ckowvrtv00vol17nvjc2zk30y"
                preventStyleDiffing={true}
                mapboxApiAccessToken={mapboxApiAccessToken}
              />
            </DeckGL>
          )
          }
        </AutoSizer>
      </div>
      <CustomCard
        active={interactingWithControls}
        setActive={setInteractingWithControls}
        style={{
          display: 'block',
          position: 'absolute',
          zIndex: 1000,
          top: '2rem',
          right: '2rem',
          minWidth: '15rem',
          padding: '1rem',
        }}
      >
        <Typography variant="h5" component="h2">
          Legend
        </Typography>
        <FormGroup>
          {layers && Object.entries(layers).map(([key, l]) =>
            (
              <FormControlLabel key={key}
                control={<Switch
                  checked={l.selected}
                  name={key} key={key}
                  onClick={() => { selectionChanged(key) }}
                  color='default'
                  style={{ color: l.color }}
                />}
                label={l.name}
              />
            )
          )}

        </FormGroup>
      </CustomCard>
      <CustomCard
        active={interactingWithControls}
        setActive={setInteractingWithControls}
        style={{
          display: 'flex',
          alignItems: 'center',
          position: 'absolute',
          zIndex: 1000,
          bottom: 0,
          padding: '1rem',
          margin: '2rem',
        }}
      >
        <Typography style={{ fontWeight: 'bold', fontFamily: 'monospace' }}>
          {new Date((startDate + time) * 1000).toISOString().substr(11, 5)}
        </Typography>
      </CustomCard>
      <CustomCard
        active={interactingWithControls}
        setActive={setInteractingWithControls}
        style={{
          position: 'absolute',
          display: 'flex',
          zIndex: 1000,
          bottom: 0,
          right: 0,
          width: '40%',
          margin: '2rem',
        }}
      >
        <CardActions style={{ width: '100%' }}>
          <IconButton aria-label="Play/Pause" onClick={handlePlayPause}>
            {running ? <Stop /> : <PlayArrow />}
          </IconButton >
          <Slider
            style={{ flex: 1 }}
            value={changingTime
              ? tentativeTime
              : 100 * time / loopLength
            }
            onChange={(_, v) => {
              if (changingTime) {
                setTentativeTime(v)
              } else {

                setTentativeTime(time)
                setChangingTime(true)
              }
            }}
            onChangeCommitted={(_, v) => {
              if (changingTime) {
                setChangingTime(false)
                setTime(v / 100 * loopLength)
              }
            }}
          />
          <Button
            style={{ height: '100%', display: 'flex', justifyContent: 'center' }}
            onClick={() => {
              handleSpeedIncrease()
              setShowSpeedIcon(false)
            }}
            onMouseEnter={() => setShowSpeedIcon(true)}
            onMouseLeave={() => setShowSpeedIcon(false)}
          >

            <Typography style={{
              position: 'absolute',
              transition: 'opacity 0.5s',
              opacity: showSpeedIcon ? 0 : 1,
              fontFamily: 'monospace',
            }}>
              {animationSpeed}x
            </Typography>
            <FastForward style={{
              position: 'absolute',
              transition: 'opacity 0.5s',
              opacity: showSpeedIcon ? 1 : 0,
            }} />
          </Button>
        </CardActions>
      </CustomCard>
    </>
  )
}


export default Commutron
